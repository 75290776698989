import { Box, Text } from "@chakra-ui/react";

interface TechChipProps {
  text: string;
  color: string;
}

const TechChip = ({ text, color }: TechChipProps): JSX.Element => {
  return (
    <Box
      px={3}
      py={0.5}
      borderRadius={20}
      borderColor={`${color}.400`}
      borderWidth={1}
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      _before={{
        content: `""`,
        bgColor: `${color}.50`,
        opacity: 0.2,
        position: "absolute",
        width: "100%",
        height: "100%",
        borderRadius: 20,
      }}
    >
      <Text
        fontSize="xs"
        fontWeight="bold"
        color={`${color}.400`}
        opacity="none"
        position="relative"
      >
        {text.toUpperCase()}
      </Text>
    </Box>
  );
};

export default TechChip;
