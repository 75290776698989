import { Stack, Box, SimpleGrid, Text } from "@chakra-ui/react";
import { ProjectData } from "../data/ProjectData";
import SectionHeader from "./SectionHeader";
import SectionContainer from "./SectionContainer";
import ProjectCard from "./ProjectCard";
import { OtherProjectData } from "../data/OtherProjectData";
import OtherProjectCard from "./OtherProjectCard";

interface ProjectsProps {
  color: string;
}

const Projects = ({ color }: ProjectsProps): JSX.Element => {
  return (
    <SectionContainer id="projects">
      <Stack as={Box} spacing={{ base: 8, md: 14 }} pb={{ base: 20, md: 36 }}>
        <SectionHeader color={color} text="Projects" />
        <SimpleGrid px={4} spacing={12} columns={{ base: 1, md: 2 }}>
          {ProjectData.map((project, index) => (
            <ProjectCard project={project} color={color} key={index} />
          ))}
        </SimpleGrid>
        <Text px={5} fontSize={22} fontWeight={400}>
          Other Projects
        </Text>
        <SimpleGrid px={4} spacing={12} columns={{ base: 1, sm: 2, md: 3 }}>
          {OtherProjectData.map((otherProject, index) => (
            <OtherProjectCard
              otherProject={otherProject}
              color={color}
              key={index}
            />
          ))}
        </SimpleGrid>
      </Stack>
    </SectionContainer>
  );
};

export default Projects;
