import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  Link,
  StackProps,
  Text,
} from "@chakra-ui/react";
import { IOtherProject } from "../data/OtherProjectData";

interface OtherProjectCardProps extends StackProps {
  otherProject: IOtherProject;
  color: string;
}

const OtherProjectCard = ({ otherProject, color }: OtherProjectCardProps) => {
  const { heading, subHeading, description, url } = otherProject;

  return (
    <Card
      borderRadius={"xl"}
      borderWidth="1px"
      borderColor="#1a1a1a"
      style={{
        boxShadow: "0px 10px 13px -7px #1a1a1a",
      }}
    >
      <CardBody
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Heading size="md" fontWeight="normal">
            {heading}
          </Heading>
          <Text pb={1} fontSize="md" color={`${color}.400`} fontWeight="bold">
            {subHeading?.toUpperCase()}
          </Text>
          <Text pt={2} pb={6} color="whiteAlpha.600">
            {description}
          </Text>
        </Box>
        <Link href={url} isExternal={true}>
          <Button color={`${color}.400`}>Repo</Button>
        </Link>
      </CardBody>
    </Card>
  );
};

export default OtherProjectCard;
