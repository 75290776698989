import {
  Stack,
  Box,
  HStack,
  Text,
  Grid,
  GridItem,
  Flex,
} from "@chakra-ui/react";

import SectionContainer from "./SectionContainer";
import SectionHeader from "./SectionHeader";
import ExperienceCarousel from "./ExperienceCarousel";
import { ExperienceData } from "../data/ExperienceData";
import ExperienceCard from "./ExperienceCard";

interface ExperienceProps {
  color: string;
}

const Experience = ({ color }: ExperienceProps): JSX.Element => {
  return (
    <SectionContainer id="experience">
      <Stack as={Box} spacing={{ base: 8, md: 14 }} pb={{ base: 20, md: 36 }}>
        <SectionHeader color={color} text="Experience" />
        <Grid ml={6} templateRows="1fr 1fr 1fr 1fr" templateColumns="auto auto">
          {ExperienceData.map((experience, index) => (
            <>
              <GridItem fontSize="xl">
                <Box height="100%" alignItems="center">
                  <Box
                    bgColor={`${color}.400`}
                    w="16px"
                    h="16px"
                    mt="6px"
                    transform="rotate(45deg)"
                    transformOrigin="center"
                  ></Box>

                  <Box
                    bgColor="whiteAlpha.600"
                    w="4px"
                    mb="-10px"
                    ml="6px"
                    h={
                      index < ExperienceData.length - 1
                        ? "100%"
                        : "calc(100% - 42px)"
                    }
                  ></Box>
                </Box>
              </GridItem>

              <GridItem>
                <ExperienceCard
                  experience={experience}
                  color={color}
                  key={index}
                />
              </GridItem>
            </>
          ))}
        </Grid>
      </Stack>
    </SectionContainer>
  );
};

export default Experience;
