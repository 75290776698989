import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  colors: {
    gray: {
      50: "#383838",
      100: "#343434",
      200: "#333333",
      300: "#2e2e2e",
      400: "#2c2c2c",
      500: "#272727",
      600: "#242424",
      700: "#222222",
      800: "#121212",
      900: "#1e1e1e",
    },
  },
});

export default theme;
