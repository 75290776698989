import { Divider, Stack, Text, HStack } from "@chakra-ui/react";

interface SectionHeaderProps {
  color: string;
  text: string;
}

const SectionHeader = ({ color, text }: SectionHeaderProps): JSX.Element => {
  return (
    <Stack align="baseline" direction="row">
      <HStack>
        <Text color={`${color}.400`} fontWeight={800}>
          {"\u25CF"}
        </Text>
        <Text fontSize={28} fontWeight={400}>
          {text}
        </Text>
      </HStack>
      <Divider orientation="horizontal" />
    </Stack>
  );
};

export default SectionHeader;
