import { Container, ContainerProps } from "@chakra-ui/react";

const SectionContainer = ({
  children,
  id,
  ...rest
}: ContainerProps): JSX.Element => {
  return (
    <Container maxW={"6xl"} id={id} {...rest}>
      {children}
    </Container>
  );
};

export default SectionContainer;
