import {
  Card,
  CardBody,
  HStack,
  Heading,
  StackProps,
  Text,
} from "@chakra-ui/react";
import { IExperience } from "../data/ExperienceData";
import Chip from "./Chip";

interface ExperienceCardProps extends StackProps {
  experience: IExperience;
  color: string;
}

const ExperienceCard = ({ experience, color }: ExperienceCardProps) => {
  const { company, role, startDate, endDate, description, skills } = experience;

  return (
    <Card
      mx={4}
      mb="20px"
      height="calc(100% - 20px)"
      borderWidth="1px"
      borderColor="#1a1a1a"
      style={{
        boxShadow: "0px 10px 13px -7px #1a1a1a",
      }}
    >
      <CardBody height="100%">
        <Heading size="md" fontWeight="normal">
          {role}
        </Heading>
        <Text fontSize="md" color={`${color}.400`} fontWeight="bold">
          {company.toUpperCase()}
        </Text>
        <Text
          pb={4}
          fontSize="md"
          color={"whiteAlpha.600"}
        >{`${startDate} - ${endDate}`}</Text>
        {description?.map((description, index) => (
          <Text pb={2} key={index} color={"whiteAlpha.600"}>
            {description}
          </Text>
        ))}
        {skills && (
          <HStack pt={4} spacing={2} flexWrap="wrap">
            {skills?.map((skill, index) => (
              <Chip text={skill} color={color} key={index} />
            ))}
          </HStack>
        )}
      </CardBody>
    </Card>
  );
};

export default ExperienceCard;
