export interface IProfile {
  siteName: string;
  heroHeading: string;
  heroSubHeading: string;
  heroDescription: string;
  about: string;
  contact: string;
  linkedin: string;
  github: string;
  email: string;
  logo: string;
}

export const ProfileData: IProfile = {
  siteName: "Chad's Portfolio",
  heroHeading: "Hey, I'm Chad.",
  heroSubHeading: "I'm a Software Developer",
  heroDescription: "(and Dog Dad)!",
  about:
    "As a Full Stack developer I have had the opportunity to take applications from conception to production. In 2022 I started my coding journey as a student in the Get Coding program. Just before completing the program, I was hired by the company to build their custom student management system, and later also became a coach. In addition to working with Get Coding, I had an opportunity to contribute to the development of a mobile application at Milk Moovement for a short time in 2023. In my free time, I continue to work on personal projects and volunteer with Canada Learning Code when I can!",
  contact:
    "If you'd like to get in touch you can find me on LinkedIn, or send me an email!",
  linkedin: "https://linkedin.com/in/chadmroberts88",
  github: "https://github.com/chadmroberts88",
  email: "chadmroberts88@gmail.com",
  logo: "Chad Roberts",
};
