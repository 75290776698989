import {
  Stack,
  Text,
  Container,
  Box,
  HStack,
  Heading,
  Center,
} from "@chakra-ui/react";
import { FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa";
import { ProfileData } from "../data/ProfileData";
import SectionHeader from "./SectionHeader";
import SectionContainer from "./SectionContainer";
import SocialButton from "./SocialButton";

interface ContactProps {
  color: string;
}

const Contact = ({ color }: ContactProps): JSX.Element => {
  return (
    <SectionContainer id="contact">
      <Stack
        as={Box}
        textAlign={"center"}
        spacing={{ base: 8, md: 14 }}
        pb={{ base: 20, md: 36 }}
      >
        <SectionHeader color={color} text="Contact" />
        <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
          <Heading fontSize={"3xl"}>How to reach me...</Heading>
          <Text
            color={"whiteAlpha.600"}
            fontSize={"xl"}
            padding={6}
            borderRadius={"xl"}
            backgroundColor={"gray.800"}
          >
            {ProfileData.contact}
          </Text>
          <Center>
            <HStack pt={4} spacing={6} backgroundColor={"gray.800"}>
              <SocialButton
                url={ProfileData.linkedin}
                icon={<FaLinkedin size={42} />}
                color={color}
                aria-label="LinkedIn Button"
              />
              <SocialButton
                url={ProfileData.github}
                icon={<FaGithub size={42} />}
                color={color}
                aria-label="GitHub Button"
              />
              <SocialButton
                email={ProfileData.email}
                icon={<FaEnvelope size={42} />}
                color={color}
                aria-label="Email Button"
              />
            </HStack>
          </Center>
        </Stack>
      </Stack>
    </SectionContainer>
  );
};

export default Contact;
