import { Button, ButtonProps } from "@chakra-ui/react";

interface MenuButtonProps extends ButtonProps {
  text: string;
}

const MenuButton = ({ text, ...rest }: MenuButtonProps): JSX.Element => {
  return (
    <Button variant="ghost" {...rest}>
      {text}
    </Button>
  );
};

export default MenuButton;
