import { Box, Heading, Image, Text, Button, Stack } from "@chakra-ui/react";
import { ProfileData } from "../data/ProfileData";
import SectionContainer from "./SectionContainer";

const dogs = require("../assets/dogs.png");

interface HeroProps {
  color: string;
}

const Hero = ({ color }: HeroProps): JSX.Element => {
  const scrollToContact = () => {
    const contactSection = document.querySelector("#contact");
    contactSection?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Heading>
        <link
          href="https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap"
          rel="stylesheet"
        />
      </Heading>
      <SectionContainer
        id="hero"
        display="flex"
        flexDir={{ base: "column", md: "row" }}
        alignItems="center"
        justifyContent="center"
        gap={12}
        pb={{ base: 20, md: 36 }}
        pt={{ base: 36, md: 52 }}
      >
        <Stack as={Box} textAlign={"center"} spacing={{ base: 8, md: 14 }}>
          <Box>
            <Heading
              mb={2}
              fontWeight={600}
              lineHeight={"100%"}
              fontSize={{ base: "3xl", sm: "4xl", md: "5xl" }}
            >
              {ProfileData.heroHeading} <br />
            </Heading>
            <Heading
              mb={2}
              fontWeight={600}
              lineHeight={"100%"}
              color={`${color}.400`}
              fontSize={{ base: "3xl", sm: "4xl", md: "5xl" }}
            >
              {ProfileData.heroSubHeading}
            </Heading>
            <Text
              color={"whiteAlpha.600"}
              backgroundColor={"gray.800"}
              fontSize={{ base: "xl", sm: "2xl", md: "3xl" }}
            >
              {ProfileData.heroDescription}
            </Text>
          </Box>

          <Button
            colorScheme={color}
            bg={`${color}.400`}
            rounded={"full"}
            alignSelf={"center"}
            px={6}
            _hover={{
              bg: `${color}.500`,
            }}
            onClick={scrollToContact}
            style={{
              animation: "breathe 2s infinite",
              transformOrigin: "center",
            }}
          >
            Connect With Me!
          </Button>
        </Stack>
        <Image
          src={dogs}
          height={{ base: 200, md: 300 }}
          borderRadius="50%"
          boxShadow="dark-lg"
        />
      </SectionContainer>
    </div>
  );
};

export default Hero;
