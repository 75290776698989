import { Box, Container, Stack, Text } from "@chakra-ui/react";

interface FooterProps {
  color: string;
}

const Footer = ({ color }: FooterProps): JSX.Element => {
  return (
    <Box bg="gray.900">
      <Container as={Stack} maxW={"6xl"} py={4} align="center">
        <Text>© 2023 Chad Roberts. All rights reserved.</Text>
      </Container>
    </Box>
  );
};

export default Footer;
