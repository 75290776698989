import {
  Text,
  HStack,
  Button,
  Card,
  CardBody,
  Image,
  Heading,
  LinkBox,
  LinkOverlay,
  Box,
  Link,
} from "@chakra-ui/react";
import { IProject } from "../data/ProjectData";
import { useState } from "react";
import Chip from "./Chip";

interface ProjectCardProps {
  project: IProject;
  color: string;
}

const ProjectCard = ({ project, color }: ProjectCardProps): JSX.Element => {
  const { heading, subHeading, description, imageUrl, techStack, links } =
    project;
  const [showText, setShowText] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const handleStartHover = (): void => {
    setOpacity(0.3);
    setShowText(true);
  };

  const handleEndHover = (): void => {
    setOpacity(1);
    setShowText(false);
  };

  return (
    <Card
      direction="column"
      height="100%"
      borderRadius={"xl"}
      style={{
        boxShadow: "0px 10px 13px -7px #1a1a1a",
      }}
    >
      {imageUrl && (
        <Box
          bg="blackAlpha.700"
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
          borderRadius={"xl"}
        >
          {showText && (
            <Heading
              fontSize="2.5rem"
              fontWeight="light"
              position="absolute"
              zIndex="1"
              pointerEvents="none"
            >
              View Live
            </Heading>
          )}
          <LinkBox
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            opacity={opacity}
            onTouchStart={handleStartHover}
            onTouchEnd={handleEndHover}
            onMouseOver={handleStartHover}
            onMouseLeave={handleEndHover}
          >
            <LinkOverlay href={links && links[0].url} isExternal={true}>
              <Image
                objectFit="cover"
                minH="200px"
                maxH="300px"
                src={imageUrl}
              />
            </LinkOverlay>
          </LinkBox>
        </Box>
      )}
      <CardBody
        alignItems="left"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Heading size="md" fontWeight="normal">
            {heading}
          </Heading>
          <Text pb={4} fontSize="md" color={`${color}.400`} fontWeight="bold">
            {subHeading?.toUpperCase()}
          </Text>
          {description?.map((text, index) => (
            <Text pb={2} key={index} color="whiteAlpha.600">
              {text}
            </Text>
          ))}
          <HStack pt={4} pb={6} spacing={2} flexWrap="wrap">
            {techStack?.map((text, index) => (
              <Chip text={text} color={color} key={index} />
            ))}
          </HStack>
        </Box>

        <HStack py={2} spacing={4} flexWrap="wrap">
          {links?.map((link, index) => (
            <Link href={link.url} isExternal={true} key={index}>
              <Button color={`${color}.400`}>{link.label}</Button>
            </Link>
          ))}
        </HStack>
      </CardBody>
    </Card>
  );
};

export default ProjectCard;
