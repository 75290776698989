import {
  Flex,
  Button,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Stack,
  IconButton,
  Image,
  useMediaQuery,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useState } from "react";
import MenuButton from "./MenuButton";

const star = require("../assets/star.gif");

interface NavProps {
  changeColorSpeed: () => void;
  color: string;
}

const Nav = ({ changeColorSpeed, color }: NavProps): JSX.Element => {
  const [scroll, setScroll] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThanMD] = useMediaQuery("(min-width: 38em)");

  const scrollToAbout = () => {
    const aboutSection = document.querySelector("#about");
    aboutSection?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToProjects = () => {
    const projectsSection = document.querySelector("#projects");
    projectsSection?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToExperience = () => {
    const experienceSection = document.querySelector("#experience");
    experienceSection?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToContact = () => {
    const contactSection = document.querySelector("#contact");
    contactSection?.scrollIntoView({ behavior: "smooth" });
  };
  const changeScroll = () =>
    document.body.scrollTop > 80 || document.documentElement.scrollTop > 80
      ? setScroll(true)
      : setScroll(false);

  window.addEventListener("scroll", changeScroll);

  return (
    <>
      <Flex
        bg="gray.900"
        px={4}
        h={16}
        boxShadow={scroll ? "base" : "none"}
        zIndex="sticky"
        position="fixed"
        as="header"
        alignItems={"center"}
        justifyContent={"space-between"}
        w="100%"
      >
        <Link onClick={changeColorSpeed}>
          <Image height="30px" src={star} />
        </Link>

        <Flex alignItems={"center"}>
          <Stack direction={"row"} spacing={7}>
            {isLargerThanMD ? (
              <>
                <MenuButton text="About" onClick={scrollToAbout} />
                <MenuButton text="Projects" onClick={scrollToProjects} />
                <MenuButton text="Experience" onClick={scrollToExperience} />
                <MenuButton text="Contact" onClick={scrollToContact} />
              </>
            ) : (
              <></>
            )}

            {isLargerThanMD ? (
              <></>
            ) : (
              <>
                <Button
                  as={IconButton}
                  icon={<HamburgerIcon />}
                  onClick={onOpen}
                ></Button>
                <Drawer placement="top" onClose={onClose} isOpen={isOpen}>
                  <DrawerOverlay />
                  <DrawerContent py={4}>
                    <DrawerBody display="flex" flexDirection="column" gap={2}>
                      <MenuButton text="About" onClick={scrollToAbout} />
                      <MenuButton text="Projects" onClick={scrollToProjects} />
                      <MenuButton
                        text="Experience"
                        onClick={scrollToExperience}
                      />
                      <MenuButton text="Contact" onClick={scrollToContact} />
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
              </>
            )}
          </Stack>
        </Flex>
      </Flex>
    </>
  );
};

export default Nav;
