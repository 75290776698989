import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { CSSProperties, useState } from "react";

interface SocialButtonProps extends IconButtonProps {
  icon: JSX.Element;
  color: string;
  url?: string;
  email?: string;
}

const SocialButton = ({
  icon,
  color,
  url,
  email,
  ...rest
}: SocialButtonProps): JSX.Element => {
  const [buttonColor, setButtonColor] = useState<string>("whiteAlpha.600");
  const [buttonStyle, setButtonStyle] = useState<CSSProperties>();

  const handleStartHover = (): void => {
    setButtonColor(`${color}.400`);
    setButtonStyle({ transform: "scale(1.2)" });
  };

  const handleEndHover = (): void => {
    setButtonColor("whiteAlpha.600");
    setButtonStyle({ transform: "scale(1)" });
  };

  const handleOpenUrl = (): void => {
    window.open(`${url}`, "_blank", "noreferrer,noopener");
  };

  const handleOpenEmail = (): void => {
    window.open(`mailto:${email}`, "_blank", "noreferrer,noopener");
  };

  return (
    <IconButton
      {...rest}
      padding={0}
      variant="link"
      onClick={email ? handleOpenEmail : handleOpenUrl}
      onTouchStart={handleStartHover}
      onTouchEnd={handleEndHover}
      onMouseOver={handleStartHover}
      onMouseLeave={handleEndHover}
      color={buttonColor}
      style={buttonStyle}
    >
      {icon}
    </IconButton>
  );
};

export default SocialButton;
