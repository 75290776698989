export interface ILink {
  url?: string;
  label?: string;
}

export interface IProject {
  heading?: string;
  subHeading?: string;
  description?: string[];
  imageUrl?: string;
  links?: ILink[];
  techStack?: string[];
}

const getCoding = require("../assets/get-coding.png");
const classroomPlanner = require("../assets/classroom-planner.gif");
const fruitFusion = require("../assets/fruit-fusion.gif");
const mackenzieCounty = require("../assets/mackenzie-county.gif");

export const ProjectData: IProject[] = [
  {
    heading: "Get Coding",
    subHeading: "Student Management System",
    imageUrl: getCoding,
    description: [
      "This custom student management system was developed to assist in managing students and coaches for the Get Coding program. Its core features include coaching session tracking, recording and monitoring student progress, and automated billing.",
      "The Get Coding App was built using React Native which facilitated the development of Android, iOS, and web versions. The backend uses several AWS services and third-party APIs. CI/CD pipelines were also set up to help manage releases.",
    ],
    techStack: [
      "React Native",
      "TypeScript",
      "Node.js",
      "GraphQL",
      "AWS",
      "AppSync",
      "DynamoDB",
      "Lambda",
      "SQS",
      "Cognito",
      "CI/CD",
    ],
    links: [
      {
        url: "https://play.google.com/store/apps/details?id=ca.getcoding.app",
        label: "Android",
      },
      {
        url: "https://apps.apple.com/ca/app/get-coding/id6444653874",
        label: "iOS",
      },
    ],
  },
  {
    heading: "Fruit Fusion",
    subHeading: "Web Game",
    imageUrl: fruitFusion,
    description: [
      "Fruit Fusion is a matching game where players must 'fuse' together pairs of like-colored fruit until no more moves can be made. This game aims to help with the development of problem solving, reasoning, and fine motor skills in kids, but can still be enjoyed by all ages!",
      "To appeal to a younger audience, this project was designed with bright colors and simple gameplay in mind. The elements are responsive so the game can be played on screens of varying size. The frontend was built using React and makes use of MaterialUI. The first iteration of the backend used a REST API built on Express, while the latest version uses a serverless framework built with AWS Lambda. It also uses a MySQL database running on Amazon’s RDS.",
    ],
    techStack: [
      "React.js",
      "JavaScript",
      "MaterialUI",
      "Serverless",
      "AWS",
      "Lambda",
      "RDS",
      "SQL",
      "Cognito",
      "Express",
      "REST APIs",
    ],
    links: [
      {
        url: "https://chadmroberts88.github.io/fruit-fusion",
        label: "Demo",
      },
      {
        url: "https://github.com/chadmroberts88/fruit-fusion",
        label: "Frontend Repo",
      },
      {
        url: "https://github.com/chadmroberts88/fruit-fusion-backend",
        label: "Backend Repo",
      },
    ],
  },
  {
    heading: "Classroom Planner",
    subHeading: "Web Application",
    imageUrl: classroomPlanner,
    description: [
      "Classroom Planner is a web application aimed at assisting teachers with planning classroom layouts and organizing student seating.",
      "This project served primarily to develop skills in JavaScript while continuing to improve on writing HTML and CSS. By adding a number of functionalities related to manipulating classroom objects, as well as creating and sorting the student list, I was able to make use of a wide range of JavaScript features and methods.",
    ],
    techStack: ["HTML", "CSS", "JavaScript", "UX/UI Design"],
    links: [
      {
        url: "https://chadmroberts88.github.io/classroom-planner",
        label: "Demo",
      },
      {
        url: "https://github.com/chadmroberts88/classroom-planner",
        label: "Repo",
      },
    ],
  },

  {
    heading: "Mackenzie County ",
    subHeading: "Coroprate Website",
    imageUrl: mackenzieCounty,
    description: [
      "Mackenzie County is Alberta’s most northern municipality. Their corporate website serves as the go-to source of information for both residents and tourists. This new website design aims to make it easy for visitors to find what they need and get the most important updates right from the landing page.",
      "I worked closely with a contractor to ensure that the County’s needs were met for the launch of this new website. Managing this project also piqued my interest in web design and software development which prompted me to dive deeper into learning these skills.",
    ],
    techStack: ["HTML", "CSS", "WordPress", "Project Management"],
    links: [
      {
        url: "https://www.mackenziecounty.com",
        label: "Website",
      },
    ],
  },
];
