export interface IExperience {
  company: string;
  role: string;
  startDate: string;
  endDate: string;
  description?: string[];
  skills?: string[];
}

export const ExperienceData: IExperience[] = [
  {
    company: "Get Coding",
    role: "Full Stack Developer",
    startDate: "Aug. 2022",
    endDate: "Present",
    description: [
      "Develops the company's custom student management system using React Native, TypeScript, Expo, Node.js, and AWS (AppSync, DynamoDB, Lambda, Cognito, SQS, etc.). By deploying a functional MVP within six weeks, 20+ coaches were able to log coaching sessions, monitor student progress, complete evaluations, and receive payments.",
    ],
  },
  {
    company: "Get Coding",
    role: "Coding Coach",
    startDate: "Aug. 2023",
    endDate: "Present",
    description: [
      "Provides instruction and guidance to students learning HTML, CSS, and JavaScript during one-on-one weekly meetings. Coaching students through building their projects and providing constructive feedback on their work helped them develop the fundamental skills needed to become a Software Developer.",
    ],
  },
  {
    company: "Mackenzie County",
    role: "Communications Coordinator",
    startDate: "Feb. 2021",
    endDate: "Nov. 2021",
    description: [
      "Managed the implementation of a new communications strategy over eight months, including the launch of a new corporate website, improvements to social media practices, and the use of more consistent messaging through several other media channels. These changes led to an overall increase in community engagement throughout the County.",
    ],
  },
  {
    company: "NL English School District",
    role: "IT Support Specialist",
    startDate: "Mar. 2018",
    endDate: "Jul. 2019",
    description: [
      "Developed and implemented plans for network infrastructure improvements and end-user device upgrades for seven remote schools in northern Labrador. By working closely with school administrators and district IT managers to determine each school's needs, students and staff at these schools received faster and more reliable access to online resources.",
    ],
  },
];
