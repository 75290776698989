import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Particles from "react-particles";
import type { Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";

import Nav from "./components/NavBar";
import Hero from "./components/Hero";
import About from "./components/About";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { particlesOptions } from "./particlesOptions";

function App() {
  const colors = useMemo(() => {
    return [
      "blue",
      "cyan",
      "green",
      "orange",
      "pink",
      "purple",
      "red",
      "teal",
      "yellow",
    ];
  }, []);

  const [colorIndex, setColorIndex] = useState(0);
  const [colorSpeed, setColorSpeed] = useState(5000);

  const timeoutId = useRef<NodeJS.Timeout>();
  const color = useRef<string>(colors[0]);

  useEffect(() => {
    color.current = colors[colorIndex];

    timeoutId.current = setTimeout(() => {
      setColorIndex((prev) =>
        colorIndex === colors.length - 1 ? 0 : prev + 1
      );
    }, colorSpeed);

    return () => {
      clearTimeout(timeoutId.current);
    };
  }, [colorIndex, colorSpeed, colors]);

  const handleChangeColorSpeed = (): void => {
    clearTimeout(timeoutId.current);
    setColorSpeed(() => (colorSpeed === 5000 ? 50 : 5000));
  };

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  return (
    <>
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={particlesOptions}
      />
      <Nav color={color.current} changeColorSpeed={handleChangeColorSpeed} />
      <Hero color={color.current} />
      <About color={color.current} />
      <Projects color={color.current} />
      <Experience color={color.current} />
      <Contact color={color.current} />
      <Footer color={color.current} />
    </>
  );
}

export default App;
