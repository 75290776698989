import { Stack, Text, Box } from "@chakra-ui/react";
import { ProfileData } from "../data/ProfileData";
import SectionContainer from "./SectionContainer";
import SectionHeader from "./SectionHeader";

interface AboutProps {
  color: string;
}

const About = ({ color }: AboutProps): JSX.Element => {
  return (
    <div>
      <SectionContainer id="about">
        <Stack as={Box} spacing={{ base: 8, md: 14 }} pb={{ base: 20, md: 36 }}>
          <SectionHeader color={color} text="About" />
          <Text
            color={"whiteAlpha.600"}
            fontSize={"xl"}
            padding={6}
            backgroundColor={"gray.800"}
            borderRadius={"xl"}
          >
            {ProfileData.about}
          </Text>
        </Stack>
      </SectionContainer>
    </div>
  );
};

export default About;
