export interface IOtherProject {
  heading?: string;
  subHeading?: string;
  description?: string;
  url?: string;
}

export const OtherProjectData: IOtherProject[] = [
  {
    heading: "Pokedex",
    subHeading: "Mobile Application",
    description:
      "A mobile Pokedex which uses the PokeAPI, React Native, and TypeScript.",
    url: "https://github.com/chadmroberts88/pokedex2",
  },
];
